import '../styles/globals.css'
import '../styles/github.css'
import type { AppProps } from 'next/app'
import { Analytics } from '@vercel/analytics/react';

function MyApp({ Component, pageProps }: AppProps) {
  let analytics;
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
    analytics = <Analytics mode={'production'} />;
  }
  return (
    <>
      <Component {...pageProps} />
      {analytics}
    </>
  )
}

export default MyApp
